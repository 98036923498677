














import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
  components: {
    VueApexCharts
  }
})
export default class BarChart extends Vue {
  @Prop() series;
  @Prop() categories;
  @Prop() type;
  @Prop() chart_title;
  @Prop() colors;

  @Global.State('lang') lang;

  loading = true;
  chartOptions = {
    plotOptions: {
      bar: {
        columnWidth: '20%',
        distributed: true,
        borderRadius: 7,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -30,
      style: {
        fontSize: '15px',
        colors: ['#304758']
      }
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          cssClass: 'grey--text lighten-2--text fill-color'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tooltip: {
        enabled: true
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          cssClass: 'grey--text lighten-2--text fill-color'
        }
      },
      title: {
        text: 'kWh'
      }
    },
    title: {
      text: '',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  };

  mounted() {
    this.loading = true;
    this.chartOptions.xaxis.categories = this.categories;
    // if (this.colors) {
    //     this.chartOptions.colors = this.colors;
    //     this.chartOptions.xaxis.labels.style.colors = this.colors;
    // }
    this.loading = false;
  }
}
