














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';
import BarChart from '@/components/BarChart.vue';
import moment from 'moment-timezone';

@Component({ components: {
    BarChart
}})
export default class SatecInformation extends Vue {
    @Prop() title;
    @Prop() selected_cabinet;
    @Prop() graph_data;
    @Prop() graph_dates;
    @Prop() selected_period;

    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;

    loading = true;

    chartSeries = [{data: [], name: 'Power Consumption'}];
    chartCategories = [];

    mounted(){
        this.loading = true;
        this.chartSeries[0].data = this.graph_data.slice(1);
        this.chartCategories = this.graph_dates.slice(1).map((date) => moment(date).tz(this.projectTimezone).format('DD/MM'));
        this.loading = false;
    }

}

